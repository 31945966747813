import React from 'react'
import { Link } from '@material-ui/core'
import CVVIcon from 'assets/card-cvv.svg'
import AlertIcon from 'assets/system-alert.svg'

const CVVMessage = ({ onHelperClick }) => {
  return (
    <div>
      Verifique o CVV do seu cartão e tente novamente.{' '}
      <Link onClick={onHelperClick} color="primary" variant="body1">
        <strong>O que é isso?</strong>
      </Link>
    </div>
  )
}

const messages = {
  zipcode_search: {},
  phone_number: {
    required: 'Celular obrigatório',
    length: 'Celular incompleto',
    invalid: 'Celular inválido',
  },
  email: {
    required: 'Email obrigatório',
    invalid: 'Email inválido',
    already_taken: 'Email já foi utilizado por outro telefone',
  },
  installments: {
    required: 'Selecione um parcela',
    minimun_amount: 'Escolha outra parcela onde valor seja maior que o mínimo de 1,00',
  },
  card_number: {
    required: 'Cartão obrigatório',
    length: 'Cartão incompleto',
    invalid: 'Cartão inválido',
    error: 'Cartão não aceito, por favor tente outro',
  },
  card_date: {
    required: 'Data obrigatória',
    invalid: 'Data inválida',
    length: 'Data incompleta',
    expired: 'Data expirada',
  },
  card_cvv: {
    required: 'CVV obrigatório',
    invalid: 'CVV inválido',
  },
  card_holder_name: {
    required: 'Nome obrigatório',
  },
  card_holder_document: {
    required: 'CPF/CNPJ obrigatório',
    length: 'CPF/CNPJ incompleto',
    invalid: 'CPF/CNPJ inválido',
  },
  billing_zip_code: {
    required: 'CEP obrigatório',
    length: 'CEP incompleto',
    invalid: 'CEP inválido',
  },
  billing_street: {
    required: 'Endereço obrigatório',
  },
  billing_city: {
    required: 'Cidade obrigatória',
  },
  billing_number: {
    required: 'Número obrigatório',
    length: 'Limite no máximo de ${max} caracteres', // eslint-disable-line no-template-curly-in-string
  },
  billing_state: {
    required: 'Estado obrigatório',
    invalid: 'Estado inválido',
  },
}
export default messages

const warnings = {
  cvv_help: {
    title: 'Código de Segurança',
    content:
      'O código de três ou quatro dígitos, localizado no verso ou na frente do cartão de crédito.',
    icon: CVVIcon,
    button: 'OK',
  },
  error_same_user: {
    title: 'Atenção!',
    content:
      'Não é possível realizar um pagamento a si mesmo. Por favor, solicite um novo link ao vendedor.',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [501],
  },
  cvv_invalid: {
    title: 'Código de segurança inválido',
    content: CVVMessage,
    icon: AlertIcon,
    button: 'OK',
    code: [63],
  },
  card_number_invalid: {
    title: 'Número do cartão inválido',
    content: 'Verifique os dados do seu cartão e tente novamente.',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [14, 30],
  },
  card_expired: {
    title: 'Cartão expirado',
    content: 'Verifique a data de validade ou tente novamente com outro cartão. ',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [54],
  },
  card_invalid: {
    title: 'Cartão inválido',
    content: 'Verifique os dados do cartão e se ele está disponível na função de crédito. ',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [58],
  },
  value_invalid: {
    title: 'Valor inválido',
    content: 'Verifique o valor total do seu pagamento e tente novamente. ',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [13],
  },
  error_unexpected: {
    title: 'Ocorreu um erro inesperado',
    content: 'Verifique os dados do seu cartão e a sua conexão com a Internet.',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [6, 19, 28, 85, 0, 91, 96, 65],
  },
  limit_exceeded: {
    title: 'Limite excedido',
    content: 'Verifique o limite de compras ou tente novamente com outro cartão.',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [61],
  },
  transaction_invalid: {
    title: 'Transação inválida',
    content: 'Verifique os dados do seu cartão e tente novamente. ',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [12],
  },
  refused: {
    title: (
      <>
        Não conseguimos <br />
        completar o pagamento
      </>
    ),
    content: '',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [24, 43, 59],
  },
  insufficient_funds: {
    title: 'Saldo insuficiente',
    content: 'Verifique o limite disponível ou tente novamente com outro cartão.',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [51],
  },
  not_authorized: {
    title: 'Pagamento não autorizado',
    content: 'Contate o banco emissor ou tente novamente com outro cartão.',
    icon: AlertIcon,
    button: 'Tentar novamente',
    code: [1, 2, 4, 5, 7, 41, 57, 62, 78, 93],
  },
  unknown_error: {
    title: 'Atenção!',
    content: 'Ocorreu um erro durante a comunicação com servidor, valide se a transação ocorreu, caso necessário tente novamente.',
    icon: AlertIcon,
    button: 'Recarregar',
  },
  captcha_error: {
    title: 'Atenção!',
    content: 'Ocorreu um erro durante a validação do Captcha, por favor tente novamente.',
    icon: AlertIcon,
    button: 'Tentar novamente',
  },
}

export const warningFindByCode = (code) => {
  const parsedCode = parseInt(code)
  let info
  if (warnings[code]) {
    info = {
      ...warnings[code],
    }
  } else if (!isNaN(parsedCode)) {
    const warn = Object.entries(warnings)
      .filter((keyValues) => {
        const item = keyValues[1]
        return (item.code && item.code?.indexOf(parsedCode) !== -1) || false
      })
      .shift()
    info = {
      ...(warn?.[1] || warnings.error_unexpected),
    }
  } else {
    info = {
      ...warnings.error_unexpected,
    }
    if (typeof code === 'string') {
      info.content = code
    }
  }
  return info
}

export { warnings }
