
import config from 'config'
import { useCallback, useEffect } from "react";
import { setSubmiting } from 'reducers/payments';
import { useDispatch } from 'react-redux';
import useRudderStack from 'utils/useRudderStack';
import { useShallow } from 'zustand/react/shallow'

import { create } from 'zustand'
import { produce } from 'immer'
import useGooglePay, { useGooglePayStore } from './useGooglePay';
import useApplePay, { useApplePayStore } from './useApplePay';


const useWalletPayStore = create((set) => ({
  walletType: 'apple_pay',
  showWallet: true,
  installment: 1,
  loading: true,
  setLoading: (loading) => set(produce((state) => { state.loading = loading})),
  setShowWallet: (response) => set(produce((state) => { state.showWallet = response})),
  setInstallment: (installment) => set(produce((state) => { state.installment = installment})),
  setWalletType: (walletType) => set(produce((state) => { state.walletType = walletType})),
}))

export const LABELS = {
  'apple_pay': 'ApplePay',
  'google_pay': 'GooglePay',
}

export const  validateCaptcha = () => {
  return new Promise((res) => {
    grecaptcha.ready(() => {
      grecaptcha.execute(config.recaptchaSiteKey, {action:  'wallet_pay_payment'}).then((token) => {
        return res(token);
      }).catch(() => {
        return false
      });
    })
  })
}

const useWalletPay = () => {
  const { rudderStack, ready: readyRudderStack } = useRudderStack();
  const {googleLoading, googleCanMakePayment} = useGooglePayStore(useShallow((state) => ( {googleLoading: state.loading, googleCanMakePayment: state.canMakePayment})));
  const {appleLoading, appleCanMakePayment} = useApplePayStore(useShallow((state) => ( {appleLoading: state.loading, appleCanMakePayment: state.canMakePayment})));
  const {setLoading, setShowWallet} = useWalletPayStore(useShallow((state) => ({setLoading: state.setLoading, setShowWallet: state.setShowWallet, walletType: state.walletType})));
  useApplePay()
  useGooglePay()

  useEffect(() => {
    if (!appleLoading || appleCanMakePayment) {
      setLoading(false)
    } else {
      setLoading(googleLoading)
    }
  }, [googleLoading, appleLoading, appleCanMakePayment])
  
  useEffect(() => {
    setShowWallet(googleCanMakePayment || appleCanMakePayment)
    readyRudderStack && rudderStack?.track(`Payment Link | ${LABELS[googleCanMakePayment ? 'google_pay' : 'apple_pay']} | Button Showed`)
  }, [googleCanMakePayment, appleCanMakePayment, readyRudderStack])

  return {
    
  }
}

export default useWalletPay;
export { useWalletPayStore };